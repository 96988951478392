import { plural } from '@lingui/macro';
import gql from 'graphql-tag';

import { OrderNode } from 'Components/OrdersAccordion/OrdersAccordion';
import routes from 'utils/routeTranslator';

export const citiesGroupedByRegionsQuery = gql`
  query CitiesGroupedByRegions {
    providerLandingCitiesByRegions {
      id
      name
      requestsCount
      cities {
        id
        name
        slug
      }
    }
  }
`;

type City = {
  id: string;
  name: string;
  slug: string;
};

type Region = {
  id: string;
  name: string;
  requestsCount: number;
  cities: City[];
};

export type RegionsResponse = {
  providerLandingCitiesByRegions: Region[];
};

export const buildOrdersTree = (regions: Region[]): OrderNode[] => {
  const getTitle = (count: number, region: string): string =>
    plural(count, {
      one: `# Zlecenie w: ${region}`,
      few: `# Zlecenia w: ${region}`,
      other: `# Zleceń w: ${region}`,
    });

  return regions.map((region) => {
    return {
      id: region.id,
      name: getTitle(region.requestsCount, region.name),
      url: '', // there is no page for region
      children: region.cities.map((city) => {
        return {
          id: city.id,
          name: city.name,
          url: routes.get('front::provider.register.city', { city: city.slug }),
        };
      }),
    };
  });
};
