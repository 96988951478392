import { useQuery } from '@apollo/react-hooks';
import classnames from 'classnames';
import React, { FC, useState } from 'react';

import OrdersAccordion from 'Components/OrdersAccordion/OrdersAccordion';
import { Button } from 'uikit/Button';
import { Heading2 } from 'uikit/Heading';

import { buildOrdersTree, citiesGroupedByRegionsQuery, RegionsResponse } from './OrdersByCities.helpers';
import './OrdersByCities.scss';

type Props = {
  classname?: string;
};

const RegisterOrdersByCities: FC<Props> = (props) => {
  const { data } = useQuery<RegionsResponse>(citiesGroupedByRegionsQuery);
  const [sectionsCount, setSectionsCount] = useState<number>(5);

  if (!data) return null;

  const hasMore = sectionsCount < data.providerLandingCitiesByRegions.length;
  const showAll = (): void => {
    setSectionsCount(data.providerLandingCitiesByRegions.length);
  };

  const ordersTree = buildOrdersTree(data.providerLandingCitiesByRegions);
  const hostCN = classnames('registerOrdersByCities', props.classname);
  return (
    <section className={hostCN} id="orders-by-city">
      <div className="container">
        <Heading2>Замовлення за містами</Heading2>
        <OrdersAccordion
          className="registerOrdersByCities__items"
          orders={ordersTree}
          numberOfVisible={sectionsCount}
        />
        {hasMore && (
          <div className="registerOrdersByCities__actions">
            <Button kind="secondary" width="wide" onClick={showAll} data-testid="show-all-regions">
              Показати більше замовлень
            </Button>
          </div>
        )}
      </div>
    </section>
  );
};

export default RegisterOrdersByCities;
