import { t } from '@lingui/macro';
import gql from 'graphql-tag';
import debounce from 'lodash.debounce';
import * as yup from 'yup';

import config from 'config/config';
import { SPOnboardingSteps } from 'pages/SPOnboarding/SPOnboarding.helpers';
import { isUserRegistered } from 'utils/auth/auth.helpers';
import { phoneFieldValidationSchema } from 'utils/graphql/spSettings';

const fieldRequiredMessage = t`To pole jest wymagane`;

export const PROVIDER_FULLNAME_REGEXP = new RegExp(
  // eslint-disable-next-line
  /^[a-zA-Z\u0080-\u024F\-,\.']+\s+([a-zA-Z\u0080-\u024F\-,\.']+(\s+[a-zA-Z\u0080-\u024F\-,\.']*)*)$/
);

const loginValidator = (lengthThreshold: number) =>
  debounce((value: string | undefined, resolve: (result: boolean) => void) => {
    if (!value || value.length < lengthThreshold) return resolve(true);
    return isUserRegistered(value).then((result) => resolve(!Boolean(result.data.checkUserRegistered)));
  }, 300);

const emailValidator = loginValidator(4);

export const providerRegisterValidationSchema = yup.object().shape({
  accountType: yup.string().nullable().required(fieldRequiredMessage),
  name: yup
    .string()
    .required(fieldRequiredMessage)
    .min(config.PROVIDER_NAME_MIN_LENGTH, t`Pole zawierać może minimalnie ${config.USERNAME_MIN_LENGTH} znaków,`)
    .max(config.PROVIDER_NAME_MAX_LENGTH, t`Pole zawierać może maksymalnie ${config.PROVIDER_NAME_MAX_LENGTH} znaków`)
    .matches(PROVIDER_FULLNAME_REGEXP, t`Pole "imię i nazwisko" zawierać musi co najmniej 2 wyrazy!`),
  cityId: yup.string().required(fieldRequiredMessage),
  phone: phoneFieldValidationSchema,
  email: yup
    .string()
    .required(fieldRequiredMessage)
    .email(t`Nieprawidłowy adres e-mail`)
    .test({
      name: 'emailAlreadyTaken',
      message: t`Ten adres e-mail jest już w naszej bazie. Podaj inny adres e-mail`,
      test: (value) => new Promise((resolve) => emailValidator(value, resolve)),
    }),
  password: yup
    .string()
    .required(fieldRequiredMessage)
    .min(config.PASSWORD_MIN_LENGTH, t`Hasło musi mieć co najmniej 6 znaków - dzięki temu będzie bezpieczniejsze`),
  l2CategoryIds: yup.array().min(1, t`Wybierz min. 1 specjalizację`),
});

export type TopCategory = {
  id: string;
  slug: string;
  name: string;
};

export enum UserRole {
  customer = 'Customer',
  provider = 'Provider',
}

export type TopCategoryResponse = {
  config: {
    provider: {
      specializationLimit: number;
    };
  };
  topCategoryList: TopCategory[];
};

export type AuthenticateOutput = {
  registerProvider: {
    userId: string;
    userRoles: UserRole[];
    refreshToken: string;
    accessToken: string;
    providerOnboardingStep: SPOnboardingSteps;
    expiresAt?: number;
  };
};

export type RegisterProviderVars = {
  input: {
    accountType: 'COMPANY' | 'PRIVATE_PERSON';
    cityId: string;
    email: string;
    l2CategoryIds: string[];
    l3CategoryIds?: string[];
    language?: string;
    name: string;
    password: string;
    phone: string;
    subscribeToMarketing: boolean;
  };
};

export const registerProviderMutation = gql`
  mutation RegisterProvider($input: RegisterProviderInput!) {
    registerProvider(input: $input) {
      userId
      userRoles
      refreshToken
      accessToken
      providerOnboardingStep
    }
  }
`;

export const topCategoriesQuery = gql`
  query TopCategories {
    config {
      provider {
        specializationLimit
      }
    }
    topCategoryList {
      id
      slug
      name
    }
  }
`;
