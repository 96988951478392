import { graphql } from '@apollo/react-hoc';
import throttle from 'lodash.throttle';
import React, { Component, ComponentType } from 'react';

import analytics from 'utils/analytics/analytics';
import { client } from 'utils/apolloConnector/apolloConnector';

import {
  LocationSearchData$Props,
  LocationSearchData$State,
  popularCitiesQuery,
  bigCitiesQuery,
  searchCitiesQuery,
  LocationSelect$Props,
  CitySuggestion,
} from './LocationSearch.helpers';

export default function withData(Comp: ComponentType<LocationSelect$Props>) {
  class LocationSearchData extends Component<LocationSearchData$Props, LocationSearchData$State> {
    state = {
      bigCities: [],
      suggestedCities: [],
    };

    preloadCities = () => {
      client
        .query<{ locationSuggestBigCities: CitySuggestion[] }>({
          query: bigCitiesQuery,
        })
        .then((res) => {
          const { locationSuggestBigCities } = res.data;
          this.setState({ bigCities: locationSuggestBigCities });
        })
        .catch((err) => {
          console.log(err);
        });
    };

    searchCities = throttle((locationQuery: string) => {
      client
        .query<{ searchLocations: CitySuggestion[] }>({
          query: searchCitiesQuery,
          variables: { locationQuery },
        })
        .then((res) => {
          const { searchLocations } = res.data;
          analytics.trackEvent('location_search_query', {
            keyword_string: locationQuery,
            suggestion_ids: searchLocations.map((s: any) => s.id),
          });

          this.setState({ suggestedCities: searchLocations });
        })
        .catch((err) => {
          console.log(err);
        });
    }, 200);

    render() {
      const { data, ...ownProps } = this.props;

      const { bigCities, suggestedCities } = this.state;

      return (
        <Comp
          /* @ts-expect-error type mismatch */
          isLoading={data.loading}
          /* @ts-expect-error type mismatch */
          popularCities={data.locationSuggestPopularCities}
          /* @ts-expect-error type mismatch */
          bigCities={bigCities}
          /* @ts-expect-error type mismatch */
          suggestedCities={suggestedCities}
          /* @ts-expect-error type mismatch */
          preloadCities={this.preloadCities}
          /* @ts-expect-error type mismatch */
          searchCities={this.searchCities}
          {...ownProps}
        />
      );
    }
  }
  /* @ts-expect-error type mismatch */
  return graphql(popularCitiesQuery)(LocationSearchData);
}
