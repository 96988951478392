import { useMutation } from '@apollo/react-hooks';
import { t, Trans } from '@lingui/macro';
import classnames from 'classnames';
import { FormikErrors, useFormik } from 'formik';
import React, { FC, ReactNode, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Select, { OptionsOrGroups } from 'react-select';

import { phoneNumberMask } from 'Components/Form/Form.helpers';
import LocationSelect from 'Components/LocationSelect/LocationSelect';
import Modal from 'common/Modal/Modal';
import emailIcon from 'common/icons/general/icon_email.svg';
import lockIcon from 'common/icons/general/icon_lock.svg';
import personIcon from 'common/icons/general/icon_person.svg';
import phoneIcon from 'common/icons/general/icon_phone.svg';
import config from 'config/config';
import { Button } from 'uikit/Button';
import FormError from 'uikit/Error/Error';
import FormRow from 'uikit/FormRow/FormRow';
import Input from 'uikit/Input/Input';
import Label from 'uikit/Label/Label';
import { TextDisplay } from 'uikit/Text';
import analytics from 'utils/analytics/analytics';
import { getFrictionChallengeHeaders, FrictionHeaders } from 'utils/api/headers';
import auth from 'utils/auth/auth';
import { getFrictionInit, JSXElementFromHTMLElement } from 'utils/captcha/useFrictionFlow';

import ProviderRegisterCategories from './ProviderRegisterCategories/ProviderRegisterCategories';
import ProviderRegisterFooter from './ProviderRegisterFooter/ProviderRegisterFooter';
import {
  AuthenticateOutput,
  providerRegisterValidationSchema,
  registerProviderMutation,
  RegisterProviderVars,
} from './ProviderRegisterForm.helpers';
import './ProviderRegisterForm.scss';

type AccountType = RegisterProviderVars['input']['accountType'];

type Option = { value: AccountType; label: string };

const accountTypeOptions: OptionsOrGroups<Option, never> = [
  {
    value: 'COMPANY',
    label: t`Firma`,
  },
  {
    value: 'PRIVATE_PERSON',
    label: t`Osoba prywatna`,
  },
];

type Props = {
  id?: string;
  title?: string;
  className?: string;
  twoStepUIEnabled?: boolean;
  onRegisterComplete?: (credentials: AuthenticateOutput['registerProvider']) => void;
  onRegisterFailed?: (error: any) => void;
  l2CategoryIds?: string[];
  l3CategoryIds?: string[];
};

const ProviderRegisterForm: FC<Props> = (props) => {
  const [register] = useMutation<AuthenticateOutput, RegisterProviderVars>(registerProviderMutation);
  const [secondStepOpen, setSecondStepOpen] = useState<boolean>(false);
  const [challengeElement, setChallengeElement] = useState<ReactNode | null>(null);
  const location = useLocation();

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    isSubmitting,
    setSubmitting,
    setFieldValue,
    validateForm,
    setFieldTouched,
  } = useFormik({
    initialValues: {
      accountType: null,
      name: '',
      cityId: '',
      email: '',
      phone: '',
      password: '',
      subscribeToMarketing: false,
      l2CategoryIds: props.l2CategoryIds || [],
    },
    validationSchema: providerRegisterValidationSchema,
    onSubmit: () => {
      const isUkrainian = location.pathname.includes('/uk/');

      const variables = {
        input: {
          ...values,
          name: values.name.trim(),
          email: values.email.trim(),
          l3CategoryIds: props.l3CategoryIds,
          language: isUkrainian ? 'uk' : undefined,
          accountType: values.accountType as unknown as AccountType,
        },
      };

      const startRegisterFlow = (): Promise<FrictionHeaders | void> => {
        if (!config.OLX_FRICTION_FLOW_ENABLED) return Promise.resolve();

        return getFrictionInit('register', values.email, (element) => {
          if (element) {
            setChallengeElement(<JSXElementFromHTMLElement htmlElement={element} />);
          } else {
            setChallengeElement(null);
          }
        }).then((challengeState) => getFrictionChallengeHeaders(challengeState));
      };

      startRegisterFlow()
        .then((headers) => {
          // register user as provider
          return register({
            context: {
              headers: headers,
            },
            variables,
          });
        })
        .then((result) => {
          // automatically login recently registered provider
          if (!result.data) {
            throw new Error(t`Coś poszło nie tak...Spróbuj ponownie`);
          }
          const { registerProvider } = result.data;
          auth.storeCredentials({
            ...registerProvider,
            isProvider: true,
          });
          return registerProvider;
        })
        .then((provider) => {
          if (props.onRegisterComplete) props.onRegisterComplete(provider);
        })
        .catch((error) => {
          if (props.onRegisterFailed) props.onRegisterFailed(error);
        })
        .finally(() => setSubmitting(false));
    },
  });

  const onNext = (errors: FormikErrors<any>): void => {
    const invalidFields = Object.keys(errors);
    const isValid = !invalidFields.length;
    const canOpenSecondStep = isValid || (invalidFields.length < 2 && invalidFields[0] === 'l2CategoryIds');
    if (canOpenSecondStep) {
      setSecondStepOpen(true);
      analytics.trackEvent('sp_registration_save_l2');
    } else {
      // highlight all errors
      Object.keys(values)
        .filter((key) => key !== 'l2CategoryIds')
        .forEach((key) => setFieldTouched(key));
    }
  };

  const onSecondStepClose = (): void => {
    setSubmitting(false);
    setSecondStepOpen(false);
    setChallengeElement(null);
  };

  const hasPrefilledCategories = props.l2CategoryIds?.length;
  const displayCategorySection = !props.twoStepUIEnabled && !hasPrefilledCategories;
  const categoriesError: string | undefined =
    touched.l2CategoryIds && typeof errors.l2CategoryIds === 'string' ? errors.l2CategoryIds : undefined;

  const hostCN = classnames('providerRegisterForm', props.className);

  return (
    <form className={hostCN} onSubmit={handleSubmit} id={props.id}>
      {props.title && (
        <FormRow>
          <TextDisplay as="h2" bold>
            {props.title}
          </TextDisplay>
        </FormRow>
      )}
      {displayCategorySection && (
        <ProviderRegisterCategories
          value={values.l2CategoryIds}
          error={categoriesError}
          onChange={(ids) => setFieldValue('l2CategoryIds', ids)}
        />
      )}
      <FormRow>
        <Input
          name="name"
          id="name"
          placeholder={t`Imię i nazwisko`}
          label={t`Wpisz imię i nazwisko`}
          iconUrl={personIcon}
          onFocus={() => analytics.trackEvent('sp_registration_name_click')}
          data-testid="providerSignup__name"
          value={values.name}
          error={touched.name && errors.name}
          onChange={handleChange}
          autoComplete="off"
          isBig
        />
      </FormRow>
      <FormRow>
        <Label htmlFor="cityId">
          <Trans>Twoja miejscowość</Trans>
        </Label>
        <LocationSelect
          /* @ts-expect-error type mismatch */
          errorMessage={errors.cityId}
          isError={touched.cityId && errors.cityId}
          placeholder={t`Twoja miejscowość`}
          onFocus={() => analytics.trackEvent('sp_registration_location_click')}
          testid="providerSignup__location"
          isCompact
          id="cityId"
          /* @ts-expect-error type mismatch */
          onSubmit={(value) => setFieldValue('cityId', value.id)}
          onChange={() => setFieldValue('cityId', '')}
        />
      </FormRow>
      <FormRow>
        <Input
          name="phone"
          type="tel"
          id="phone"
          mask={phoneNumberMask}
          placeholder="+48 XXX XXX XXX"
          label={t`Numer telefonu`}
          iconUrl={phoneIcon}
          data-testid="providerSignup__phone"
          value={values.phone}
          error={touched.phone && errors.phone}
          onChange={handleChange}
          onFocus={() => analytics.trackEvent('sp_registration_phone_click')}
          autoComplete="off"
          isBig
        />
      </FormRow>
      <FormRow>
        <Input
          name="email"
          type="email"
          id="email"
          placeholder="E-mail"
          label={t`E-mail`}
          iconUrl={emailIcon}
          data-testid="providerSignup__email"
          value={values.email}
          error={touched.email && errors.email}
          onChange={handleChange}
          onFocus={() => analytics.trackEvent('sp_registration_mail_click')}
          autoComplete="off"
          isBig
        />
      </FormRow>
      <FormRow>
        <Input
          name="password"
          type="password"
          id="password"
          placeholder={t`Wpisz hasło`}
          label={t`Hasło`}
          iconUrl={lockIcon}
          data-testid="providerSignup__password"
          value={values.password}
          error={touched.password && errors.password}
          onChange={handleChange}
          onFocus={() => analytics.trackEvent('sp_registration_password_click')}
          autoComplete="off"
          isBig
        />
      </FormRow>
      <FormRow>
        <Label>
          <Trans>Rodzaj konta</Trans>
        </Label>
        <Select
          isMulti={false}
          value={accountTypeOptions.find((o) => o.value === values.accountType)}
          options={accountTypeOptions}
          placeholder={<Trans>Wybierz rodzaj</Trans>}
          onChange={(event) => {
            setFieldValue('accountType', event?.value);
            event?.value &&
              analytics.trackEvent('sp_account_type_choose', {
                touch_point_page: 'registration',
              });
          }}
          components={{
            IndicatorSeparator: () => null,
          }}
          classNames={{
            placeholder: () => 'providerRegisterForm__selectPlaceholder',
            singleValue: () => 'providerRegisterForm__selectValue',
            control: (state) =>
              classnames('providerRegisterForm__selectControl', {
                'providerRegisterForm__selectControl_focus': state.isFocused,
                'providerRegisterForm__selectControl_invalid': touched.accountType && errors.accountType,
              }),
          }}
        />
        {touched.accountType && errors.accountType && <FormError>{errors.accountType}</FormError>}
      </FormRow>
      {!props.twoStepUIEnabled && (
        <ProviderRegisterFooter
          subscribeChecked={values.subscribeToMarketing}
          onSubscribeChange={(value) => setFieldValue('subscribeToMarketing', value)}
          challengeElement={challengeElement}
        />
      )}
      {!props.twoStepUIEnabled && (
        <Button
          type="submit"
          kind="cta"
          width="full"
          className="providerRegisterForm__action"
          data-testid="providerSignup__submit"
          disabled={isSubmitting}
        >
          <Trans>Zarejestruj się</Trans>
        </Button>
      )}
      {props.twoStepUIEnabled && (
        <Button
          type="button"
          kind="cta"
          width="full"
          className="providerRegisterForm__action"
          data-testid="providerSignup__next"
          disabled={isSubmitting}
          onClick={() => {
            validateForm().then(onNext);
          }}
        >
          <Trans>Zarejestruj się</Trans>
        </Button>
      )}

      {props.twoStepUIEnabled && (
        <Modal isOpen={secondStepOpen} handleClose={onSecondStepClose} bodyClassName="providerRegisterForm__secondStep">
          <ProviderRegisterCategories
            value={values.l2CategoryIds}
            error={categoriesError}
            onChange={(value) => setFieldValue('l2CategoryIds', value)}
          />
          <ProviderRegisterFooter
            subscribeChecked={values.subscribeToMarketing}
            onSubscribeChange={(value) => setFieldValue('subscribeToMarketing', value)}
            challengeElement={challengeElement}
          />
          <Button
            type="submit"
            kind="cta"
            width="full"
            form={props.id}
            className="providerRegisterForm__action"
            disabled={isSubmitting}
          >
            <Trans>Prześlij</Trans>
          </Button>
        </Modal>
      )}
    </form>
  );
};

export default ProviderRegisterForm;
