import gql from 'graphql-tag';
import { QueryProps } from 'react-apollo';

type LocationItem = {
  id: string;
  slug: string;
  name: string;
};

export type CitySuggestion = {
  id: string;
  name: string;
  lat: number;
  long: number;
  hasCityDistricts?: boolean;
  region: {
    id: string;
    name: string;
  };
};

interface LocationSearchData extends QueryProps {
  locationSuggestBigCities: CitySuggestion[];
}

export type LocationSelect$Props = {
  city?: CitySuggestion;
  popularCities: CitySuggestion[];
  bigCities: CitySuggestion[];
  suggestedCities: CitySuggestion[];
  predefinedCities: CitySuggestion[];
  errorMessage?: string;
  isError?: boolean;
  isSticky?: boolean;
  isCompact?: boolean;
  isLocationAutodetect?: boolean;
  placeholder?: string;
  testid?: string;
  neighbourhood?: LocationItem;
  onSubmit: (suggestion: CitySuggestion) => void;
  onError?: () => void;
  onChange?: (value: string) => void;
  onFocus?: (e: any) => void;
  inputRef?: (el: HTMLInputElement | null) => void;
  onInputBlur?: () => void;
  onCityPrefilled?: (suggestion: CitySuggestion) => void;
  preloadCities: Function;
  searchCities: Function;
  id?: string;
};

export type LocationSelect$State = {
  isLoadingSuggestions: boolean;
  isDropDownOpen: boolean;
  isDetectLocationLoading: boolean;
  isLocationPermissionDenied: boolean;
  inputValue: string;
  suggestions: CitySuggestion[];
  selectedSuggestion: CitySuggestion | undefined;
};

export type LocationSearchData$Props = LocationSelect$Props & {
  data: LocationSearchData;
};

export type LocationSearchData$State = {
  bigCities: CitySuggestion[];
  suggestedCities: CitySuggestion[];
};

export const searchCityByCoordsQuery = gql`
  query searchCityQuery($latitude: Float!, $longitude: Float!) {
    searchCity(latitude: $latitude, longitude: $longitude) {
      id
      name
      lat
      long
      region {
        id
        name
      }
    }
  }
`;

export const bigCitiesQuery = gql`
  query locationSuggestBigCitiesQuery {
    locationSuggestBigCities {
      id
      name
      lat
      long
      hasCityDistricts
      region {
        id
        name
      }
    }
  }
`;

export const popularCitiesQuery = gql`
  query locationSuggestPopularCitiesQuery {
    locationSuggestPopularCities {
      id
      name
      lat
      long
      hasCityDistricts
      region {
        id
        name
      }
    }
  }
`;

export const searchCitiesQuery = gql`
  query searchLocationsQuery($locationQuery: String!) {
    searchLocations(locationQuery: $locationQuery) {
      id
      name
      lat
      long
      hasCityDistricts
      region {
        id
        name
      }
    }
  }
`;
